import React, {
	useCallback,
	useState,
	useEffect,
	useRef,
	useMemo,
	useLayoutEffect,
} from 'react';
import {
	VariableSizeList as List,
	ListOnItemsRenderedProps,
} from 'react-window';
import {
	useGetHistoryFirstPageQuery,
	useGetHistoryNextPagesQuery,
} from '../../../store/api';
import styles from './History.module.scss';
import Avatar from '../../Avatar/Avatar';
import { UUID, HistoryEvent } from 'sharedTypes';
import { format, isSameDay } from 'date-fns';
import { fr } from 'date-fns/locale';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';

const ITEMS_PER_PAGE = 20;

const CHANGE_TRANSLATIONS: {
	[key: string]: { modified: string; added: string; removed: string };
} = {
	// General
	title: {
		modified: 'Titre modifié',
		added: 'Titre ajouté',
		removed: 'Titre supprimé',
	},
	context: {
		modified: 'Contexte modifié',
		added: 'Contexte ajouté',
		removed: 'Contexte supprimé',
	},
	required: {
		modified: 'Obligation de réponse modifiée',
		added: 'Obligation de réponse ajoutée',
		removed: 'Obligation de réponse supprimée',
	},
	type: {
		modified: 'Type de question modifié',
		added: 'Type de question ajouté',
		removed: 'Type de question supprimé',
	},
	coverImage: {
		modified: 'Image de contexte modifiée',
		added: 'Image de contexte ajoutée',
		removed: 'Image de contexte supprimée',
	},

	// SingleSelect / MultipleSelect
	options: {
		modified: 'Options modifiées',
		added: 'Options ajoutées',
		removed: 'Options supprimées',
	},
	criticalOptions: {
		modified: 'Options critiques modifiées',
		added: 'Options critiques ajoutées',
		removed: 'Options critiques supprimées',
	},
	hasCriticalOptions: {
		modified: 'Activation des options critiques modifiée',
		added: 'Activation des options critiques ajoutée',
		removed: 'Activation des options critiques supprimée',
	},
	other: {
		modified: 'Option "Autre" modifiée',
		added: 'Option "Autre" ajoutée',
		removed: 'Option "Autre" supprimée',
	},

	// Number
	criticalEnabled: {
		modified: 'Activation de la valeur critique modifiée',
		added: 'Activation de la valeur critique ajoutée',
		removed: 'Activation de la valeur critique supprimée',
	},
	mode: {
		modified: 'Mode de comparaison modifié',
		added: 'Mode de comparaison ajouté',
		removed: 'Mode de comparaison supprimé',
	},
	criticalValue: {
		modified: 'Valeur critique modifiée',
		added: 'Valeur critique ajoutée',
		removed: 'Valeur critique supprimée',
	},

	// Text
	criticalValues: {
		modified: 'Valeurs critiques modifiées',
		added: 'Valeurs critiques ajoutées',
		removed: 'Valeurs critiques supprimées',
	},

	// Media
	mediaType: {
		modified: 'Type de média modifié',
		added: 'Type de média ajouté',
		removed: 'Type de média supprimé',
	},
	maxFiles: {
		modified: 'Nombre maximum de fichiers modifié',
		added: 'Nombre maximum de fichiers ajouté',
		removed: 'Nombre maximum de fichiers supprimé',
	},
	videoMaxDuration: {
		modified: 'Durée maximale de vidéo modifiée',
		added: 'Durée maximale de vidéo ajoutée',
		removed: 'Durée maximale de vidéo supprimée',
	},
	hashing: {
		modified: 'Hachage modifié',
		added: 'Hachage ajouté',
		removed: 'Hachage supprimé',
	},
	orientationEnabled: {
		modified: "Activation de l'orientation modifiée",
		added: "Activation de l'orientation ajoutée",
		removed: "Activation de l'orientation supprimée",
	},
	orientation: {
		modified: 'Orientation modifiée',
		added: 'Orientation ajoutée',
		removed: 'Orientation supprimée',
	},

	// Date
	period: {
		modified: 'Période modifiée',
		added: 'Période ajoutée',
		removed: 'Période supprimée',
	},
};

const DEFAULT_VALUES: { [key: string]: any } = {
	media: {
		mediaType: 'photo',
		maxFiles: 1,
		videoMaxDuration: 30,
		hashing: false,
		orientationEnabled: false,
		orientation: 'portrait',
	},
	singleSelect: {
		options: [],
		criticalOptions: [],
		hasCriticalOptions: false,
		other: false,
	},
	multipleSelect: {
		options: [],
		criticalOptions: [],
		hasCriticalOptions: false,
		other: false,
	},
	text: {
		criticalValues: [],
	},
	number: {
		criticalEnabled: false,
		mode: 'equal',
		criticalValue: 0,
	},
	date: {
		period: null,
	},
};

const formatValue = (value: any): string | JSX.Element => {
	if (Array.isArray(value)) {
		if (value.some((item) => item.includes('amazonaws.com'))) {
			return (
				<div className={styles.imageGrid}>
					{value.map((url, index) => (
						<img
							key={index}
							src={url}
							alt={`Media ${index + 1}`}
							className={styles.historyImage}
						/>
					))}
				</div>
			);
		}
		return value.join(', ');
	}
	if (typeof value === 'string' && value.includes('amazonaws.com')) {
		return <img src={value} alt="Media" className={styles.historyImage} />;
	}
	if (typeof value === 'boolean') {
		return '';
	}
	if (typeof value === 'object' && value !== null) {
		return JSON.stringify(value);
	}
	return String(value);
};

const TRANSLATIONS: { [key: string]: string } = {
	// Types
	singleSelect: 'Sélection unique',
	multipleSelect: 'Sélection multiple',
	text: 'Texte',
	number: 'Nombre',
	date: 'Date',
	media: 'Média',

	// Orientations
	landscape: 'Paysage',
	portrait: 'Portrait',
	square: 'Carré',

	// Media
	photo: 'Photo',
	video: 'Vidéo',
	file: 'Fichier',

	// Number
	equal: 'Nombre précis',
	lessThan: 'En dessous de',
	greaterThan: 'Au dessus de',

	// States
	enabled: 'Activé',
	disabled: 'Désactivé',

	// Actions
	added: 'Ajouté',
	modified: 'Modifié',
	removed: 'Supprimé',

	orientationEnabled: 'Orientation activée',
	orientationDisabled: 'Orientation désactivée',
	hashing: 'Hachage',
	other: 'Autre',
	criticalOptions: 'Options critiques',
	hasCriticalOptions: 'Options critiques activées',
};
const translateTerm = (term: string): string => {
	return TRANSLATIONS[term] || term;
};

const HistoryItem = ({
	event,
	index,
	style,
	setItemSize,
	isFirstEventOfDay,
}: {
	event: {
		date: string;
		user: { firstName: string; lastName: string };
		eventTitle: string;
		thisTitle: string;
		changes: { change: string; old?: string; new: string }[];
	};
	index: number;
	style: React.CSSProperties;
	setItemSize: (index: number, size: number) => void;
	isFirstEventOfDay: boolean;
}) => {
	const itemRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		if (itemRef.current) {
			setItemSize(index, itemRef.current.getBoundingClientRect().height);
		}
	}, [setItemSize, index, event]);

	return (
		<div style={{ ...style, height: 'auto' }}>
			<div ref={itemRef} className={styles.historyItem}>
				{isFirstEventOfDay && (
					<div className={styles.date}>
						{format(new Date(event.date), 'd MMMM yyyy', {
							locale: fr,
						}).toUpperCase()}
					</div>
				)}
				<div className={styles.historyInfo}>
					<div className={styles.content}>
						<div className={styles.title}>
							<span>{translateTerm(event.eventTitle)}</span>
						</div>
						{event.thisTitle && (
							<div className={styles.subTitle}>
								{translateTerm(event.thisTitle)}
							</div>
						)}
						{event.changes.map((change, idx) => (
							<div key={idx}>
								{change.old !== undefined ? (
									<div className={styles.changes}>
										<div className={styles.changesContent}>
											{translateTerm(change.change)}
										</div>
										<div className={styles.oldModif}>
											<div className={styles.modifLabel}>Ancien</div>
											<div className={styles.modifContent}>
												{typeof change.old === 'string'
													? formatValue(translateTerm(change.old))
													: change.old}
											</div>
										</div>
										<div className={styles.newModif}>
											<div className={styles.modifLabel}>Nouveau</div>
											<div className={styles.modifContent}>
												{typeof change.new === 'string'
													? formatValue(translateTerm(change.new))
													: change.new}
											</div>
										</div>
									</div>
								) : (
									<div className={styles.changes}>
										<div className={styles.changesContent}>
											{translateTerm(change.change)}
										</div>
										<div className={styles.newModif}>
											<div className={styles.modifContent}>
												{typeof change.new === 'string'
													? formatValue(translateTerm(change.new))
													: change.new}
											</div>
										</div>
									</div>
								)}
							</div>
						))}
						<div className={styles.user}>
							<div className={styles.flex}>
								<Avatar
									medium
									id={event.user.firstName + event.user.lastName}
									name={`${event.user.firstName} ${event.user.lastName}`}
								/>
								<div className={styles.userColumn}>
									<div className={`${styles.userName} ${styles.historyUser}`}>
										{`${event.user.firstName} ${event.user.lastName}`}
									</div>
								</div>
							</div>
							<span>{format(new Date(event.date), 'HH:mm')}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const History = ({
	close,
	checklistId,
}: {
	close: () => void;
	checklistId: UUID;
}) => {
	const [page, setPage] = useState(1);
	const [allItemsLoaded, setAllItemsLoaded] = useState(false);
	const [firstPageEvents, setFirstPageEvents] = useState<HistoryEvent[]>([]);
	const [nextPagesEvents, setNextPagesEvents] = useState<HistoryEvent[]>([]);
	const [allEvents, setAllEvents] = useState<HistoryEvent[]>([]);
	const [listHeight, setListHeight] = useState(400);
	const containerRef = useRef<HTMLDivElement>(null);
	const [itemSizes, setItemSizes] = useState<{ [key: number]: number }>({});
	const listRef = useRef<List>(null);
	const [isLoadingMore, setIsLoadingMore] = useState(false);

	const { data: firstPageData, isLoading: isFirstPageLoading } =
		useGetHistoryFirstPageQuery({
			checklistId,
			limit: ITEMS_PER_PAGE,
		});

	const { data: nextPagesData, isFetching: isNextPagesFetching } =
		useGetHistoryNextPagesQuery(
			{
				checklistId,
				page,
				limit: ITEMS_PER_PAGE,
			},
			{ skip: page === 1 }
		);

	useEffect(() => {
		// Reset events when the component is mounted
		setFirstPageEvents([]);
		setNextPagesEvents([]);
		setAllEvents([]);
		setPage(1);
		setAllItemsLoaded(false);
	}, [checklistId]);

	useEffect(() => {
		if (firstPageData) {
			setFirstPageEvents(firstPageData.events);
			if (firstPageData.currentPage === firstPageData.totalPages) {
				setAllItemsLoaded(true);
			} else {
				setPage(2);
			}
		}
	}, [firstPageData]);

	useEffect(() => {
		if (nextPagesData) {
			setNextPagesEvents((previous) => [...previous, ...nextPagesData.events]);
			if (nextPagesData.currentPage === nextPagesData.totalPages) {
				setAllItemsLoaded(true);
			}
			setIsLoadingMore(false);
		}
	}, [nextPagesData]);

	useEffect(() => {
		setAllEvents([...firstPageEvents, ...nextPagesEvents]);
	}, [firstPageEvents, nextPagesEvents]);

	const loadMore = useCallback(() => {
		if (!isNextPagesFetching && !allItemsLoaded && !isLoadingMore) {
			setIsLoadingMore(true);
			setPage((prev) => prev + 1);
		}
	}, [isNextPagesFetching, allItemsLoaded, isLoadingMore]);

	const isEmptyValue = useCallback((value: any): boolean => {
		if (
			value === undefined ||
			value === null ||
			value === '' ||
			value === 'undefined'
		) {
			return true;
		}

		if (Array.isArray(value)) {
			return value.length === 0;
		}

		if (typeof value === 'object') {
			return Object.values(value).every(isEmptyValue);
		}

		return false;
	}, []);

	const compareAndAddChanges = useCallback(
		(changes: any, oldObj: any, newObj: any, prefix = '') => {
			if (!oldObj && !newObj) return;

			Object.keys({ ...oldObj, ...newObj }).forEach((key) => {
				const oldValue = oldObj?.[key];
				const newValue = newObj?.[key];

				if (key === 'required') return;

				const fullKey = prefix ? `${prefix}.${key}` : key;
				const translationKey = key.includes('.') ? key.split('.').pop()! : key;
				const translation = CHANGE_TRANSLATIONS[translationKey];

				if (
					typeof newValue === 'object' &&
					newValue !== null &&
					!Array.isArray(newValue) &&
					(!oldValue || typeof oldValue !== 'object')
				) {
					Object.entries(newValue).forEach(([subKey, subValue]) => {
						const subTranslationKey = subKey.includes('.')
							? subKey.split('.').pop()!
							: subKey;
						const subTranslation = CHANGE_TRANSLATIONS[subTranslationKey];

						if (
							!isEmptyValue(subValue) &&
							subValue !== DEFAULT_VALUES[key]?.[subKey]
						) {
							changes.push({
								change: subTranslation?.added || subKey,
								new: formatValue(subValue),
							});
						}
					});
					return;
				}

				if (
					typeof oldValue === 'object' &&
					typeof newValue === 'object' &&
					!Array.isArray(oldValue) &&
					!Array.isArray(newValue)
				) {
					compareAndAddChanges(changes, oldValue, newValue, fullKey);
					return;
				}

				if (oldValue !== newValue) {
					if (
						(key === 'multipleSelect' || key === 'singleSelect') &&
						newValue?.options
					) {
						changes.push({
							change: CHANGE_TRANSLATIONS['options']?.added || 'options',
							new: newValue.options.join(', '),
						});
						return;
					}

					if (typeof newValue === 'boolean') {
						if (newValue) {
							changes.push({
								change: translation?.added || key,
							});
						} else {
							changes.push({
								change: translation?.removed || key,
							});
						}
					} else {
						if (!isEmptyValue(newValue) && !isEmptyValue(oldValue)) {
							changes.push({
								change: translation?.modified || key,
								old: formatValue(oldValue),
								new: formatValue(newValue),
							});
						} else if (
							!isEmptyValue(newValue) &&
							newValue !== DEFAULT_VALUES[key]
						) {
							changes.push({
								change: translation?.added || key,
								new: formatValue(newValue),
							});
						} else if (!isEmptyValue(oldValue)) {
							changes.push({
								change: translation?.removed || key,
								old: formatValue(oldValue),
							});
						}
					}
				}
			});
		},
		[isEmptyValue]
	);

	const transformEvent = useCallback(
		(event: HistoryEvent) => {
			const { timestamp, userFirstName, userLastName } = event;

			let eventTitle = '';
			let thisTitle = '';

			const changes: {
				change?: string;
				old?: string;
				new: string;
			}[] = [];

			switch (event.type) {
				// Checklist
				case 'checklistUpdate':
					if (event.old) {
						eventTitle = `Checklist modifiée`;
						compareAndAddChanges(changes, event.old, event.new);
					} else {
						eventTitle = `Checklist créée`;
						thisTitle = `#${event.new}`;
					}
					break;

				// Chapter
				case 'chapterCreate':
					const isOldEmpty =
						event.old &&
						Object.values(event.old).every((value) => isEmptyValue(value));

					if (!event.old || isOldEmpty) {
						eventTitle = 'Chapitre créé';
						compareAndAddChanges(changes, {}, event.new);
					} else {
						eventTitle = 'Chapitre modifié';
						thisTitle = `#${event.new.title || event.old.title || event.title}`;
						compareAndAddChanges(changes, event.old, event.new);
					}
					break;

				case 'chapterDelete':
					eventTitle = 'Chapitre supprimé';
					thisTitle = event.title;
					break;

				// Question
				case 'questionCreate':
					const isQuestionOldEmpty =
						event.old &&
						Object.values(event.old).every((value) => isEmptyValue(value));

					if (!event.old || isQuestionOldEmpty) {
						eventTitle = 'Question créée';
					} else {
						eventTitle = 'Question modifiée';
						thisTitle = `#${event.new.title || event.old.title || event.title}`;
					}

					compareAndAddChanges(changes, event.old, event.new);

					if (event.old.required === true && event.new.required === false) {
						changes.push({
							new: 'Réponse obligatoire désactivée',
						});
					} else if (
						event.old.required === false &&
						event.new.required === true
					) {
						changes.push({
							new: 'Réponse obligatoire activée',
						});
					}
					break;

				case 'questionDelete':
					eventTitle = 'Question supprimée';
					thisTitle = `#${event.title}`;
					break;

				case 'questionDuplicate':
					eventTitle = 'Question dupliquée';
					thisTitle = `#${event.title}`;
					break;
				default:
					throw new Error(`Type d'événement non géré : ${(event as any).type}`);
			}

			return {
				date: timestamp,
				user: { firstName: userFirstName, lastName: userLastName },
				eventTitle,
				thisTitle,
				changes,
			};
		},
		[compareAndAddChanges, isEmptyValue]
	);

	const memoizedEvents = useMemo(
		() =>
			allEvents
				.map(transformEvent)
				.filter(
					(event) =>
						event.changes.length > 0 ||
						event.eventTitle === 'Question dupliquée' ||
						event.eventTitle === 'Question supprimée'
				),
		[allEvents, transformEvent]
	);

	const setItemSize = useCallback((index: number, size: number) => {
		setItemSizes((prev) => {
			if (prev[index] === size) return prev;
			return { ...prev, [index]: size };
		});
	}, []);

	const getItemSize = useCallback(
		(index: number) => {
			return itemSizes[index] || 100;
		},
		[itemSizes]
	);

	const Item = useCallback(
		({ index, style }: { index: number; style: React.CSSProperties }) => {
			const event = memoizedEvents[index];
			if (!event) return null;
			const isFirstEventOfDay =
				index === 0 ||
				!isSameDay(
					new Date(allEvents[index - 1].timestamp),
					new Date(event.date)
				);

			return (
				<HistoryItem
					event={event as any}
					index={index}
					style={style}
					setItemSize={setItemSize}
					isFirstEventOfDay={isFirstEventOfDay}
				/>
			);
		},
		[memoizedEvents, allEvents, setItemSize]
	);

	useEffect(() => {
		const updateHeight = () => {
			if (containerRef.current) {
				setListHeight(containerRef.current.clientHeight);
			}
		};

		updateHeight();
		window.addEventListener('resize', updateHeight);

		return () => window.removeEventListener('resize', updateHeight);
	}, []);

	const handleItemsRendered = useCallback(
		({ overscanStopIndex }: ListOnItemsRenderedProps) => {
			if (
				!isNextPagesFetching &&
				!allItemsLoaded &&
				!isLoadingMore &&
				overscanStopIndex >= allEvents.length - 5
			) {
				loadMore();
			}
		},
		[
			isNextPagesFetching,
			allItemsLoaded,
			isLoadingMore,
			allEvents.length,
			loadMore,
		]
	);

	useEffect(() => {
		if (listRef.current) {
			listRef.current.resetAfterIndex(0);
		}
	}, [itemSizes]);

	return (
		<div className={styles.history}>
			<div className={styles.flex}>
				<h4>Historique</h4>
				<button className={styles.noBorderButton} onClick={close}>
					<CloseIcon />
				</button>
			</div>
			<div className={styles.historyList} ref={containerRef}>
				{isFirstPageLoading ? (
					<div>Chargement...</div>
				) : (
					<List
						ref={listRef}
						height={listHeight}
						itemCount={allEvents.length}
						itemSize={getItemSize}
						width="100%"
						className={styles.list}
						onItemsRendered={handleItemsRendered}
					>
						{Item}
					</List>
				)}
				{isLoadingMore && <div>Chargement de plus d'événements...</div>}
			</div>
		</div>
	);
};

export default React.memo(History);
