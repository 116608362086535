import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../config';

const baseUrl = config.baseUrl;

export const createApiWithBaseQuery = (reducerPath: string) =>
	createApi({
		reducerPath,
		baseQuery: fetchBaseQuery({
			baseUrl,
			prepareHeaders: (headers) => {
				const token = localStorage.getItem('token');
				const tokenType = localStorage.getItem('tokenType');

				if (token) {
					headers.set('authorization', `Bearer ${token}`);
					headers.set('x-token-type', tokenType || 'standard');
				}
				return headers;
			},
		}),
		endpoints: () => ({}),
	});
