import { useMemo, useState, useEffect, useRef } from 'react';
import { getSocket } from '../store/socket';
import InvitePopup from './Checklist/InvitePopup/InvitePopup';
import Checklist from './Checklist/Checklist';
import { useDeviceType } from '../useDeviceType';
import { useNavigation } from '../NavigationContext';
import {
	Checklist as ChecklistType,
	Chapter as ChapterType,
	Question as QuestionType,
	Rule,
	Response,
} from '../sharedTypes';
import {
	useGetChecklistQuery,
	useGetAnswerQuery,
	useGetResponsesQuery,
} from '../store/api';
import { isAnswered } from './Checklist/checklistUtils';
import { ReactComponent as MailIcon } from './icons/mail.svg';
import { ReactComponent as ArrowBackIcon } from './icons/arrow_back.svg';
import NoResponsePage from './Response/NoResponsePage';
import Comments from './Comments';
import ResponsesMenu from './Response/ResponsesMenu';
import QuestionMenu from './Response/QuestionMenu';
import ScrollableMobileResponse from './Response/ScrollableMobileResponse';
import ResponsePage from './Response/ResponsePage';
import Navbar from './Navbar/Navbar';
import PdfGenerator from './Pdf/PdfGenerator';
import config from '../config';
import styles from './Checklist/Checklist.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

const ResponseChecklist = ({
	id,
	response,
	refetchResponses,
}: {
	id: string;
	response: Response & { folderId: string };
	refetchResponses: () => void;
}) => {
	const { isTypeOrLarger } = useDeviceType();
	const { navigation, setNavigation } = useNavigation();
	const { data } = useGetAnswerQuery(response.responseId, {
		skip: !response.responseId,
	});
	const isFirstRender = useRef(true);

	const responseWithRulesApplied = useMemo(() => {
		const toMaskQuestions: string[] = [];

		response.state?.chapters?.forEach((chapter: ChapterType) => {
			chapter.questions?.forEach((question: QuestionType) => {
				if (data && data[question.questionId]) {
					if (question.rules) {
						question.rules.forEach((rule: Rule) => {
							if (rule.condition) {
								// If the answer is
								if (
									(rule.value as string[]).includes(
										data[question.questionId] as any
									) // the chosen option
								) {
									if (!rule.action) {
										// then hide
										rule.targetQuestionIds?.forEach((targetQuestionId) => {
											toMaskQuestions.push(targetQuestionId);
										});
									}
								} else {
									// not the chosen option
									if (rule.action) {
										// then hide
										rule.targetQuestionIds?.forEach((targetQuestionId) => {
											toMaskQuestions.push(targetQuestionId);
										});
									}
								}
							} else {
								// If the answer is
								if (
									(rule.value as string[]).includes(
										data[question.questionId] as any
									) // the chosen option
								) {
									if (rule.action) {
										// then hide
										rule.targetQuestionIds?.forEach((targetQuestionId) => {
											toMaskQuestions.push(targetQuestionId);
										});
									}
								} else {
									// not the chosen option
									if (!rule.action) {
										// then hide
										rule.targetQuestionIds?.forEach((targetQuestionId) => {
											toMaskQuestions.push(targetQuestionId);
										});
									}
								}
							}
						});
					}
				} else {
					if (!isAnswered(question.questionId)) {
						question.rules?.forEach((rule: Rule) => {
							rule.targetQuestionIds?.forEach((targetQuestionId) => {
								toMaskQuestions.push(targetQuestionId);
							});
						});
					}
				}
			});
		});

		const filteredChapter = response.state?.chapters
			?.map((chapter: ChapterType) => {
				const newQuestions = chapter.questions?.filter(
					(question: QuestionType) =>
						!toMaskQuestions.includes(question.questionId)
				);
				return { ...chapter, questions: newQuestions };
			})
			.filter((chapter) => chapter.questions?.length);

		return {
			...response.state,
			chapters: filteredChapter,
			folderId: response.folderId,
		};
	}, [response, data]);

	useEffect(() => {
		if (
			isFirstRender.current &&
			isTypeOrLarger('md') &&
			responseWithRulesApplied?.chapters?.length > 0
		) {
			const firstChapter = responseWithRulesApplied.chapters[0];
			if ((firstChapter.questions || []).length > 0) {
				setNavigation(`checklist_${response.state.checklistId}`);
			}
			isFirstRender.current = false;
		}
	}, [isTypeOrLarger, responseWithRulesApplied, setNavigation, response]);

	if (!data) return null;

	return (
		<div className={styles.responseContainer}>
			<div
				// style={{ height: 'calc(100% - 4.5rem)', gap: 0 }}
				className={styles.flex}
			>
				<Checklist
					id={id}
					data={responseWithRulesApplied as ChecklistType}
					values={data}
					responseId={response.responseId}
					mode="response"
					navigation={navigation}
					setNavigation={setNavigation}
					refetchResponses={refetchResponses}
				/>
			</div>
		</div>
	);
};

const ResponsesChecklist = ({
	id,
	checklist,
	response,
	responses,
	refetchResponses,
}: {
	id: string;
	checklist?: ChecklistType;
	response: Response & { folderId: string; responseId: string };
	responses: Response[];
	refetchResponses: () => void;
}) => {
	const {
		navigation,
		setNavigation,
		// , setOpenedResponse
	} = useNavigation();
	const { data } = useGetAnswerQuery(response.responseId, {
		skip: !response.responseId,
	});
	const isIntegrated = useSelector(
		(state: RootState) => state.user.isIntegrated
	);
	const [commentsOn, setCommentsOn] = useState(false);
	const [openInvitePopup, setOpenInvitePopup] = useState(false);
	const [isAutoNavigationDisabled, setIsAutoNavigationDisabled] =
		useState(true);
	const [copied, setCopied] = useState(false);
	const [selectedComment, setSelectedComment] = useState<{
		roomId: string;
		entityType: 'checklist' | 'chapter' | 'question';
		entityId: string;
	}>({
		roomId: `checklist-${response.state?.checklistId}`,
		entityType: 'checklist',
		entityId: response.state?.checklistId,
	});

	useEffect(() => {
		if (navigation.startsWith('question')) {
			setSelectedComment({
				roomId: navigation,
				entityType: 'question',
				entityId: navigation.split('_')[2],
			});
		}
	}, [navigation, response]);

	useEffect(() => {
		getSocket()?.emit('joinResponse', id);
		// return () => {
		// 	getSocket()?.emit('leaveResponse', id);
		// };
	}, [id]);

	const responseWithRulesApplied = useMemo(() => {
		const toMaskQuestions: string[] = [];

		response.state?.chapters?.forEach((chapter: ChapterType) => {
			chapter.questions?.forEach((question: QuestionType) => {
				if (data && data[question.questionId]) {
					if (question.rules) {
						question.rules.forEach((rule: Rule) => {
							if (rule.condition) {
								// If the answer is
								if (
									(rule.value as string[]).includes(
										data[question.questionId] as any
									) // the chosen option
								) {
									if (!rule.action) {
										// then hide
										rule.targetQuestionIds?.forEach((targetQuestionId) => {
											toMaskQuestions.push(targetQuestionId);
										});
									}
								} else {
									// not the chosen option
									if (rule.action) {
										// then hide
										rule.targetQuestionIds?.forEach((targetQuestionId) => {
											toMaskQuestions.push(targetQuestionId);
										});
									}
								}
							} else {
								// If the answer is
								if (
									(rule.value as string[]).includes(
										data[question.questionId] as any
									) // the chosen option
								) {
									if (rule.action) {
										// then hide
										rule.targetQuestionIds?.forEach((targetQuestionId) => {
											toMaskQuestions.push(targetQuestionId);
										});
									}
								} else {
									// not the chosen option
									if (!rule.action) {
										// then hide
										rule.targetQuestionIds?.forEach((targetQuestionId) => {
											toMaskQuestions.push(targetQuestionId);
										});
									}
								}
							}
						});
					}
				} else {
					// if (!isAnswered(question.questionId)) {
					// 	question.rules?.forEach((rule: Rule) => {
					// 		rule.targetQuestionIds?.forEach((targetQuestionId) => {
					// 			toMaskQuestions.push(targetQuestionId);
					// 		});
					// 	});
					// }
				}
			});
		});

		const filteredChapter = response.state?.chapters
			?.map((chapter: ChapterType) => {
				const newQuestions = chapter.questions?.filter(
					(question: QuestionType) =>
						!toMaskQuestions.includes(question.questionId)
				);
				return { ...chapter, questions: newQuestions };
			})
			.filter((chapter) => chapter.questions?.length);

		return { ...response.state, chapters: filteredChapter };
	}, [response, data]);

	const responsesWithRulesApplied = useMemo(() => {
		return responses.map((resp) => {
			const toMaskQuestions: string[] = [];

			resp.state.chapters?.forEach((chapter: ChapterType) => {
				chapter.questions?.forEach((question: QuestionType) => {
					if (data && data[question.questionId]) {
						if (question.rules) {
							question.rules.forEach((rule: Rule) => {
								if (rule.condition) {
									if (
										(rule.value as string[]).includes(
											data[question.questionId] as any
										)
									) {
										if (!rule.action) {
											rule.targetQuestionIds?.forEach((targetQuestionId) => {
												toMaskQuestions.push(targetQuestionId);
											});
										}
									} else {
										if (rule.action) {
											rule.targetQuestionIds?.forEach((targetQuestionId) => {
												toMaskQuestions.push(targetQuestionId);
											});
										}
									}
								} else {
									if (
										(rule.value as string[]).includes(
											data[question.questionId] as any
										)
									) {
										if (rule.action) {
											rule.targetQuestionIds?.forEach((targetQuestionId) => {
												toMaskQuestions.push(targetQuestionId);
											});
										}
									} else {
										if (!rule.action) {
											rule.targetQuestionIds?.forEach((targetQuestionId) => {
												toMaskQuestions.push(targetQuestionId);
											});
										}
									}
								}
							});
						}
					} else {
						if (!isAnswered(question.questionId)) {
							question.rules?.forEach((rule: Rule) => {
								rule.targetQuestionIds?.forEach((targetQuestionId) => {
									toMaskQuestions.push(targetQuestionId);
								});
							});
						}
					}
				});
			});

			const filteredChapter = resp.state?.chapters
				?.map((chapter: ChapterType) => {
					const newQuestions = chapter.questions?.filter(
						(question: QuestionType) =>
							!toMaskQuestions.includes(question.questionId)
					);
					return { ...chapter, questions: newQuestions };
				})
				.filter((chapter) => chapter.questions?.length);

			return { ...resp, state: { ...resp.state, chapters: filteredChapter } };
		});
	}, [responses, data]);

	// useEffect(() => {
	// 	getSocket()?.on('responseCreated', (data: Response) => {
	// 		const newInviteLink = `${config.baseUrl
	// 			.replace('/api', '')
	// 			.replace('localhost:3000', 'localhost:3001')}/?response=${
	// 			data.responseId
	// 		}`;
	// 		navigator.clipboard.writeText(newInviteLink);
	// 		setOpenedResponse(data);
	// 		setCopied(true);
	// 		setTimeout(() => setCopied(false), 2000);
	// 	});
	// }, [setOpenedResponse]);

	const handleCopyLink = () => {
		const newInviteLink = `${config.baseUrl
			.replace('/api', '')
			.replace('localhost:3000', 'localhost:3001')}/?response=${
			response.responseId
		}`;
		navigator.clipboard.writeText(newInviteLink);
		setCopied(true);
		setTimeout(() => setCopied(false), 2000);
	};

	return (
		<div className={styles.responseContainer}>
			<>
				<Navbar
					before={
						isIntegrated ? null : (
							<button
								className={styles.noBorderButton}
								onClick={() => setNavigation(`folder_${response.folderId}`)}
							>
								<ArrowBackIcon />
							</button>
						)
					}
					after={
						<div className={styles.flex}>
							{!isIntegrated && (
								<button
									onClick={() => {
										if (response && response.responseId) {
											handleCopyLink();
										} else {
											setOpenInvitePopup(true);
										}
									}}
									className={styles.primaryButton}
								>
									<MailIcon />
									{response && response.responseId && copied
										? 'Copié !'
										: 'Inviter à remplir'}
								</button>
							)}
							{response.responseId && (
								<PdfGenerator response={response as any} id={id} />
							)}
						</div>
					}
				/>
				<div
					style={{ height: 'calc(100% - 4.5rem)', gap: 0 }}
					className={styles.flex}
				>
					{!isIntegrated && (
						<ResponsesMenu
							checklist={checklist}
							currentAnswers={data}
							responses={responsesWithRulesApplied as Response[]}
							checklistId={id}
							setNavigation={setNavigation}
						/>
					)}
					{data && responses.length > 0 ? (
						<>
							{navigation.startsWith('question') ? (
								<ScrollableMobileResponse
									id={id}
									data={responseWithRulesApplied as ChecklistType}
									values={data}
									responseId={response.responseId}
									isAutoNavigationDisabled={isAutoNavigationDisabled}
									setIsAutoNavigationDisabled={setIsAutoNavigationDisabled}
									setCommentsOn={setCommentsOn}
									commentsOn={commentsOn}
								/>
							) : (
								<ResponsePage
									checklist={responseWithRulesApplied as ChecklistType}
									checklistId={id}
									response={response}
									responseIndex={responses.findIndex(
										(r) => r.responseId === response.responseId
									)}
									firstQuestion={
										(
											responseWithRulesApplied.chapters?.[
												0 as number
											] as ChapterType
										)?.questions?.[0] as any
									}
									currentAnswers={data}
									setNavigation={setNavigation}
								/>
							)}
						</>
					) : (
						<NoResponsePage
							setOpenInvitePopup={setOpenInvitePopup}
							checklist={checklist}
							openComments={() => {
								setCommentsOn(true);
								setSelectedComment({
									roomId: `checklist-${checklist?.checklistId}`,
									entityType: 'checklist',
									entityId: checklist?.checklistId || '',
								});
							}}
						/>
					)}
					{openInvitePopup && (
						<InvitePopup
							close={() => {
								setOpenInvitePopup(false);
								refetchResponses();
							}}
						/>
					)}
					{commentsOn ? (
						<Comments
							roomId={selectedComment.roomId}
							entityType={selectedComment.entityType}
							entityId={selectedComment.entityId}
							close={() => setCommentsOn(false)}
							noBorder
						/>
					) : (
						!navigation.startsWith('checklist') &&
						data && (
							<QuestionMenu
								data={responseWithRulesApplied as any}
								values={data}
								setIsAutoNavigationDisabled={setIsAutoNavigationDisabled}
							/>
						)
					)}
				</div>
			</>
		</div>
	);
};

export default function ChecklistContainer({
	id,
	response,
}: {
	id: string;
	response?: Response;
}) {
	const { isTypeOrLarger } = useDeviceType();
	const { navigation, setNavigation, responseMode } = useNavigation();
	const { data } = useGetChecklistQuery(id);
	const { data: responses, refetch } = useGetResponsesQuery(id);

	return (
		<div style={{ height: '100%' }}>
			{responseMode ? (
				isTypeOrLarger('md') ? (
					<ResponsesChecklist
						id={id}
						response={
							{ ...response, folderId: data?.folderId || '' } as Response & {
								folderId: string;
							}
						}
						checklist={data as ChecklistType | undefined}
						responses={responses || []}
						refetchResponses={refetch}
					/>
				) : (
					<ResponseChecklist
						id={id}
						response={
							{ ...response, folderId: data?.folderId || '' } as Response & {
								folderId: string;
							}
						}
						refetchResponses={refetch}
					/>
				)
			) : (
				<>
					{data && (
						<Checklist
							id={id}
							data={{
								...data.state,
								folderId: data.folderId,
								lastModified: {
									...data?.lastModified,
									timestamp: new Date(data?.lastModified?.timestamp || ''),
								},
							}}
							mode="edit"
							navigation={navigation}
							setNavigation={setNavigation}
							refetchResponses={refetch}
						/>
					)}
				</>
			)}
		</div>
	);
}
