import { useCallback, useEffect } from 'react';
import { useGetFoldersQuery } from '../../store/api';
import { getSocket } from '../../store/socket';
import { useNavigation } from '../../NavigationContext';
import { ReactComponent as AddIcon } from '../icons/add.svg';
import { ReactComponent as FolderIcon } from '../icons/folder.svg';
import FolderList from './FolderList';
import Navbar from 'components/Navbar/Navbar';
import styles from './Home.module.scss';

export default function Home() {
	const { setNavigation, setOpenedChecklistId } = useNavigation();
	const { data: folders } = useGetFoldersQuery({});

	useEffect(() => {
		document.title = 'Dashboard | Dossiers';
	}, []);

	const handleNewFolder = () => {
		getSocket()?.emit('createFolder', {
			name: 'Nouveau dossier',
		});
	};

	const handleNewChecklist = useCallback(() => {
		const exists = folders?.find((folder) => folder.name === 'Non classées');

		if (exists) {
			getSocket()?.emit('createChecklist', {
				folderId: exists.folderId,
				title: 'Nouvelle checklist',
			});
		} else {
			getSocket()?.emit('createFolder', { name: 'Non classées' });

			getSocket()?.on('folderCreated', (response) => {
				const folderId = response.folderId;

				getSocket()?.emit('createChecklist', {
					folderId,
					title: 'Nouvelle checklist',
				});
			});
		}

		getSocket()?.on('checklistCreated', (response) => {
			setNavigation(`checklist_${response.checklistId}`);
			setOpenedChecklistId(response.checklistId);
		});
	}, [folders, setNavigation, setOpenedChecklistId]);

	return (
		<div className={styles.home}>
			<Navbar />
			<div
				className={`${styles.homeContent} ${
					!folders?.length ? styles.fixedHeight : ''
				}`}
			>
				<div className={styles.flex}>
					<div className={styles.homeItemContainer}>
						<FolderIcon />
					</div>
					<div className={styles.homeItemText}>
						{folders?.length
							? `${folders.length} Dossier${folders.length === 1 ? '' : 's'}`
							: 'aucun'}
						<h2>Dossiers</h2>
					</div>
					<div className={styles.homeItemButtonContainer}>
						{folders?.length ? (
							<button onClick={handleNewFolder} className={styles.greyButton}>
								Nouveau dossier
							</button>
						) : null}
						<button
							onClick={handleNewChecklist}
							className={`${
								folders?.length ? styles.iconButton : styles.greyButton
							}`}
						>
							<AddIcon fill="#FFFFFF" />
							Nouvelle Checklist
						</button>
					</div>
				</div>

				<div
					className={`${styles.content} ${
						!folders?.length ? styles.heightAuto : ''
					}`}
				>
					<div className={styles.navigationContainer}>
						<div className={styles.navigation}>
							<div className={`${styles.navigationItem} ${styles.active}`}>
								Toutes les Checklists
							</div>
						</div>
					</div>

					{folders?.length ? (
						<FolderList />
					) : (
						<div className={styles.emptyData}>
							<div className={styles.emptyDataInfo}>
								<h2>Aucun dossier pour le moment</h2>
								<div className={styles.description}>
									Organisez vos Checklists en fonction des thèmes, domaines ou
									objectifs de votre choix, en créant votre premier dossier
								</div>
							</div>
							<button
								onClick={handleNewFolder}
								className={`${styles.iconButton} ${styles.fitContentWidth}`}
							>
								<AddIcon fill="#FFFFFF" />
								Créer un dossier
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
