import { useEffect, useRef, useState } from 'react';
import { useGetFoldersQuery } from '../../store/api';
import { ReactComponent as FolderStrokeIcon } from 'components/icons/folder_stroke.svg';
import styles from './FoldersListPopup.module.scss';
import { Folder as FolderType } from '../../sharedTypes';

const FoldersListPopup = ({
	left,
	top,
	onMoveToFolder,
	close,
}: {
	left: number;
	top: number;
	onMoveToFolder: (folderId: string) => void;
	close: () => void;
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const [contentWidth, setContentWidth] = useState(0);
	const [topState, setTopState] = useState(top);
	const [value, setValue] = useState('');
	const { data, isFetching } = useGetFoldersQuery({ q: value });
	const folders = data?.slice()?.sort((a: any, b: any) => {
		// Put the folder 'sans dossier' last in the list
		if (!a.id) return 1;
		if (!b.id) return -1;
		return 0;
	});

	useEffect(() => {
		if (ref.current) {
			const rect = ref.current.getBoundingClientRect();
			setContentWidth(rect.width + 14);
		}
		inputRef.current?.focus();
	}, []);

	useEffect(() => {
		if (ref.current) {
			const buttonRect = ref.current.getBoundingClientRect();
			const spaceBelow = window.innerHeight - buttonRect.bottom;
			setTopState(spaceBelow < 0 ? top - buttonRect.height : top);
		}
	}, [top]);

	useEffect(() => {
		// Handler for clicks outside the popup
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				setValue('');
				close();
			}
		};

		// Add event listener for mousedown (or click) events
		document.addEventListener('mousedown', handleClickOutside);

		// Cleanup the event listener on component unmount
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [close, setValue]);

	return (
		<div
			ref={ref}
			className={styles.foldersListPopup}
			style={{
				left: `${left - contentWidth}px`,
				top: `${topState}px`,
			}}
		>
			<div className={styles.searchContainer}>
				<input
					ref={inputRef}
					className={styles.inputSearch}
					type="text"
					placeholder="Chercher ou créer un dossier"
					value={value}
					onChange={(e) => setValue(e.target.value)}
				/>
			</div>
			<div className={styles.subtitle}>
				{folders?.length === 0 ? 'Aucun dossier trouvé ' : 'Tous les dossiers'}
			</div>

			<div
				className={`${styles.content} ${
					isFetching ? styles.loadingHeight : ''
				}`}
			>
				{isFetching ? (
					<div className={styles.fetchingSpinner}>
						<span />
					</div>
				) : (
					folders?.map((folder: FolderType) => (
						<div
							key={`${folder.folderId}-${folder.name}`}
							className={styles.folderItem}
							onClick={() => onMoveToFolder(folder.folderId)}
						>
							<div className={styles.folderIcon}>
								<FolderStrokeIcon />
							</div>
							{folder.name}
						</div>
					))
				)}
			</div>
		</div>
	);
};

export default FoldersListPopup;
