import { useEffect, useRef, ReactElement, JSXElementConstructor } from 'react';
// import { ReactComponent as DragIcon } from './icons/drag.svg';
import { ReactComponent as ChapterBurgerIcon } from './icons/chapter_burger.svg';
import { useSprings, animated, config } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { clamp } from 'lodash';

function swap(arr: number[], fromIndex: number, toIndex: number) {
	const newArray = arr.slice();
	const [removed] = newArray.splice(fromIndex, 1);
	newArray.splice(toIndex, 0, removed);
	return newArray;
}

const fn =
	(
		gap: number,
		order: number[],
		heights: number[],
		active = false,
		originalIndex = 0,
		y = 0,
		initialRender = true
	) =>
	(index: number) => {
		const yPos =
			order
				?.slice(0, order.indexOf(index))
				.reduce((acc, curIdx) => acc + heights[curIdx] + gap, 0) || 0;

		return active && index === originalIndex
			? {
					y,
					// scale: 1.1,
					scale: 1,
					zIndex: 1,
					shadow: 15,
					immediate: (key: string) => key === 'zIndex',
					config: (key: string) =>
						key === 'y' ? config.stiff : config.default,
			  }
			: {
					y: yPos,
					scale: 1,
					zIndex: 0,
					shadow: 0,
					immediate: initialRender,
			  };
	};

export default function DraggableList({
	items,
	order,
	setOrder,
	heights,
	gap = 0,
	disableInitialAnimation = false,
	translateY = 1.3,
	undraggableItems,
	itemsData,
	burgerIcon,
	noIcon,
}: {
	items: ReactElement<any, string | JSXElementConstructor<any>>[];
	order: number[];
	setOrder: (order: number[]) => void;
	heights: number[];
	gap?: number;
	disableInitialAnimation?: boolean;
	translateY?: number;
	undraggableItems?: string[];
	itemsData?: { type: string; data: {} }[];
	burgerIcon?: string[];
	noIcon?: string[];
}) {
	const [springs, api] = useSprings(items.length, fn(gap, order, heights));
	const questionRefs = useRef<(HTMLDivElement | null)[]>([]);

	useEffect(() => {
		api.start(fn(gap, order, heights, false, 0, 0, disableInitialAnimation));
	}, [api, gap, order, heights, disableInitialAnimation]);

	let initialY = 0;

	const bind = useDrag(
		({
			args: [originalIndex],
			active,
			movement: [, y],
			first,
			offset: [, offsetY],
		}) => {
			setTimeout(() => {
				const curIndex = order.indexOf(originalIndex);
				const curYPos = order
					.slice(0, curIndex)
					.reduce((acc, curIdx) => acc + heights[curIdx], 0);

				let targetIndex: number = 0;
				let offsetYPos = curYPos + y;

				for (let i = 0, sum = 0; i < heights.length; i++) {
					sum += heights[order[i]] + gap / 2;

					if (y > 0) {
						if (
							sum - heights[order[curIndex]] + heights[order[i + 1]] / 2 >
								offsetYPos ||
							i === heights.length - 1
						) {
							targetIndex = i;
							break;
						}
					} else {
						if (sum - heights[order[i]] / 2 > offsetYPos) {
							targetIndex = i;
							break;
						}
					}
				}
				const curRow = clamp(targetIndex, 0, items.length - 1);

				if (first) {
					initialY = order
						.slice(0, order.indexOf(originalIndex))
						.reduce((acc, curIdx) => acc + heights[curIdx] + gap, 0);
				}

				const newOrder = swap(order, curIndex, curRow);
				api.start(
					fn(gap, newOrder, heights, active, originalIndex, initialY + y, false)
				);

				if (!active) {
					setOrder(newOrder);
					initialY = 0;
				}
			}, 0);
		}
	);

	if (!springs) return null;

	return (
		<div
			style={{
				height:
					heights &&
					heights.reduce((acc, h) => acc + h, 0) + (items.length - 1) * gap,
				position: 'relative',
				touchAction: 'none',
			}}
		>
			{springs.map(({ shadow, y, scale }, i) => (
				<animated.div
					key={i}
					ref={(el) => {
						questionRefs.current[i] = el;
					}}
					onMouseDown={() => {
						questionRefs.current.forEach((ref, idx) => {
							if (ref) {
								ref.style.zIndex = '0';
								if (idx === i) {
									ref.style.zIndex = '10';
								}
							}
						});
					}}
					style={{
						boxShadow: shadow.to(
							(s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
						),
						y,
						scale,
						borderRadius: 8,
						position: 'absolute',
						width: '100%',
						touchAction: 'none',
					}}
				>
					{noIcon && noIcon?.includes(items[i].key as string) ? (
						<div
							style={{
								display: 'flex',
								userSelect: 'none',
								touchAction: 'none',
							}}
							{...bind(i)}
						>
							{items[i]}
						</div>
					) : (
						<div
							style={{
								display: 'flex',
								userSelect: 'none',
								touchAction: 'none',
							}}
						>
							{undraggableItems?.includes(items[i].key as string) ? null : (
								<div
									{...bind(i)}
									style={{
										position: 'absolute',
										transform: `translate(1.25rem, ${translateY}rem)`,
										cursor: 'grab',
										touchAction: 'none',
										width: '1.25rem',
										height: '1.25rem',
									}}
								>
									<ChapterBurgerIcon
										style={{
											width: '1.25rem',
											height: '1.25rem',
											touchAction: 'none',
										}}
									/>
									{/* {itemsData &&
									itemsData[i].type === 'chapter' &&
									burgerIcon?.includes(
										(itemsData[i].data as { chapterId: string }).chapterId
									) ? (
										<ChapterBurgerIcon
											style={{
												width: '1.25rem',
												height: '1.25rem',
												marginLeft: '0.5rem',
											}}
										/>
									) : (
										<DragIcon style={{ width: '1.5rem', height: '1.5rem' }} />
									)} */}
								</div>
							)}
							{undraggableItems?.includes(items[i].key as string) &&
							itemsData &&
							itemsData[i].type === 'chapter' &&
							burgerIcon?.includes(
								(itemsData[i].data as { chapterId: string }).chapterId
							) ? (
								<div
									style={{
										position: 'absolute',
										transform: `translate(1.25rem, ${translateY}rem)`,
										cursor: 'not-allowed',
										touchAction: 'none',
										width: '1.25rem',
										height: '1.25rem',
									}}
								>
									<ChapterBurgerIcon
										style={{
											width: '1.25rem',
											height: '1.25rem',
											touchAction: 'none',
										}}
									/>
								</div>
							) : null}

							{items[i]}
						</div>
					)}
				</animated.div>
			))}
		</div>
	);
}
