import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { getSocket } from '../../../store/socket';
import FoldersListPopup from '../../Home/FoldersListPopup';
import styles from './SimplePopup.module.scss';

type PopupList = {
	icon?: ReactNode;
	iconAtLast?: boolean;
	name: string;
	type?: string;
};

const SimplePopup = ({
	top,
	left,
	height,
	title,
	deleteOption = false,
	data,
	dividerItem,
	checklistId,
	handleDelete = () => {},
	handleInputChange = () => {},
	handleActions,
	close,
}: {
	top: number;
	left: number;
	height: number;
	title: string;
	deleteOption?: boolean;
	data: PopupList[];
	dividerItem?: number[];
	checklistId?: string;
	handleDelete?: (title: string) => void;
	handleInputChange?: (value: string) => void;
	handleActions: (title: string, name: string) => void;
	close: () => void;
}) => {
	const popupRef = useRef<HTMLDivElement>(null);
	const popupListFoldersRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLTextAreaElement>(null);
	const inputItem = data.find((item) => item.type === 'input');
	const [inputValue, setInputValue] = useState(inputItem ? inputItem.name : '');
	const [hasSelectedText, setHasSelectedText] = useState(false);
	const [topState, setTopState] = useState(top + 8 ?? 0);
	const [topSecondPopup, setTopSecondPopup] = useState(top + 8 ?? 0);
	const [onListFoldersPopup, setOnListFoldersPopup] = useState(false);

	const debouncedUpdate = useRef(
		debounce((name) => handleInputChange(name), 500)
	).current;

	useEffect(() => {
		const textarea = inputRef.current;
		if (textarea) {
			textarea.focus();
			textarea.style.height = '24px';
			textarea.style.height = `${textarea.scrollHeight - 24}px`;

			if (!hasSelectedText && inputRef.current) {
				textarea.setSelectionRange(0, inputValue.length);
				setHasSelectedText(true);
			}
		}
	}, [inputValue, hasSelectedText]);

	useEffect(() => {
		if (inputItem && inputItem.name) {
			setInputValue(inputItem.name);
		}
	}, [inputItem]);

	useEffect(() => {
		if (popupRef?.current) {
			const buttonRect = popupRef.current.getBoundingClientRect();
			const spaceBelow = window.innerHeight - buttonRect.bottom;
			setTopState(
				spaceBelow < 0 ? top - height - 8 - buttonRect.height : top + 8
			);
			setTopSecondPopup(spaceBelow < 0 ? top - height - 8 : top + 8);
		}
	}, [height, top]);

	useEffect(() => {
		// Handler for clicks outside the popup
		const handleClickOutside = (event: MouseEvent) => {
			if (
				popupRef.current &&
				!popupRef.current.contains(event.target as Node) &&
				((popupListFoldersRef.current &&
					!popupListFoldersRef.current.contains(event.target as Node)) ||
					!popupListFoldersRef.current)
			) {
				close();
			}
		};

		// Add event listener for mousedown (or click) events
		document.addEventListener('mousedown', handleClickOutside);

		// Cleanup the event listener on component unmount
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [close]);

	const onMoveToFolder = useCallback(
		(newFolderId: string) => {
			if (checklistId) {
				getSocket()?.emit('moveChecklistToFolder', {
					checklistId,
					newFolderId,
				});
				close();
			}
		},
		[checklistId, close]
	);

	return (
		<>
			<div
				ref={popupRef}
				className={styles.simplePopup}
				style={{ top: topState, left }}
			>
				<div className={styles.title}>{title}</div>
				{data.map((item, index) => (
					<div
						key={`popup_item_${index}`}
						className={`${styles.popupItem} ${
							Array.isArray(dividerItem) && dividerItem.includes(index + 1)
								? styles.borderBtm
								: ''
						} ${item.type ? styles.removeVerticalPadding : ''} ${
							item.iconAtLast ? styles.iconAtLast : ''
						}`}
						onClick={() => {
							if (!item.type) {
								if (item.name.toLowerCase().includes('déplacer')) {
									setOnListFoldersPopup(true);
								} else {
									handleActions(title, item.name);
								}
							}
						}}
					>
						{item.type ? (
							<div className={styles.search}>
								<textarea
									ref={inputRef}
									value={inputValue}
									onChange={(e) => {
										setInputValue(e.target.value);
										debouncedUpdate(e.target.value);
									}}
									className={styles.resizableTextarea}
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											e.preventDefault();
										}
									}}
									placeholder="Saisir le nom"
								/>
							</div>
						) : (
							<>
								{item.iconAtLast
									? [
											<span key={`name_${item.name}`}>{item.name}</span>,
											<span key={`icon_${item.name}`}>{item.icon}</span>,
									  ]
									: [
											<span key={`icon_${item.name}`}>{item.icon}</span>,
											<span key={`name_${item.name}`}>{item.name}</span>,
									  ]}
							</>
						)}
					</div>
				))}

				{deleteOption ? (
					<div
						className={styles.deleteOption}
						onClick={() => handleDelete(title)}
					>
						Supprimer
					</div>
				) : null}
			</div>

			{onListFoldersPopup && (
				<div ref={popupListFoldersRef}>
					<FoldersListPopup
						left={left}
						top={topSecondPopup}
						onMoveToFolder={onMoveToFolder}
						close={() => setOnListFoldersPopup(false)}
					/>
				</div>
			)}
		</>
	);
};

export default SimplePopup;
