import { useState, useEffect, useRef } from 'react';
import './App.scss';
import Home from './components/Home/Home';
import Folder from './components/Home/Folder';
import LoginForm from './LoginForm';
import ChecklistContainer from './components/ChecklistContainer';
import Toast from 'components/Toast/Toast';
import LoadingFullScreen from './components/LoadingFullScreen/LoadingFullScreen';
import { NavigationProvider, useNavigation } from './NavigationContext';
import { useGetResponseQuery } from './store/api';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import {
	setUserData,
	setAuthenticated,
	setIsIntegrated,
} from './store/userSlice';
import { useMeQuery } from './store/userApi';
import { RootState } from './store/rootReducer';
import { disconnectSocket, getSocket } from './store/socket';
import { useSignupMutation, useLoginMutation } from './store/userApi';

const PASSWORD = 'admin123';

if (process.env.NODE_ENV === 'production') {
	const TRACKING_ID = process.env.REACT_APP_TRACKING_ID as string;
	ReactGA.initialize(TRACKING_ID);
}

const Routes = () => {
	const {
		navigation,
		openedChecklistId,
		setOpenedChecklistId,
		openedResponse,
		setOpenedResponse,
		responseMode,
		setResponseMode,
		setNavigation,
	} = useNavigation();
	const isIntegrated = useSelector(
		(state: RootState) => state.user.isIntegrated
	);
	const [urlCLId, setUrlCLId] = useState<string | null>(null);
	const [urlFolderId, setUrlFolderId] = useState<string | null>(null);
	const [urlResponseId, setUrlResponseId] = useState<string | null>(null);
	const { data: urlResponse } = useGetResponseQuery(urlResponseId || '', {
		skip: !urlResponseId,
	});

	const userData = useSelector((state: RootState) => state.user.userData);
	const executedRef = useRef(false);

	useEffect(() => {
		if (
			userData &&
			// (userData.email.includes('exetanch') || userData.email.includes('3d')) &&
			false &&
			// (userData.email.includes('exetanch') || userData.email.includes('3d')) &&
			!executedRef.current
		) {
			const exetanchChecklistId = 'ac9eae97-a86b-47a7-8246-72c9f7235314';

			const socket = getSocket();
			const intervalId = setInterval(() => {
				if (socket?.connected) {
					clearInterval(intervalId);

					socket.emit('joinChecklist', exetanchChecklistId);

					socket.emit('createResponse', {
						checklistId: exetanchChecklistId,
					});

					const timeoutId = setTimeout(() => {
						window.location.reload();
					}, 1000);

					socket.on('responseCreated', (response) => {
						clearTimeout(timeoutId);
						if (!executedRef.current) {
							setOpenedResponse(response);
							setOpenedChecklistId(exetanchChecklistId);
							setNavigation(`checklist_${exetanchChecklistId}`);
							setResponseMode(true);
							executedRef.current = true;
						}
					});
				}
			}, 100);
		} else {
			const urlParams = new URLSearchParams(window.location.search);
			const responseId = urlParams.get('response');
			const folderId = urlParams.get('folder');
			const checklistId = urlParams.get('checklist');
			if (responseId) {
				setUrlResponseId(responseId);
			} else if (folderId) {
				setUrlFolderId(folderId);
			} else if (checklistId) {
				setUrlCLId(checklistId);
			}
		}
	}, [
		setUrlCLId,
		setUrlFolderId,
		setUrlResponseId,
		setNavigation,
		setOpenedResponse,
		setOpenedChecklistId,
		setResponseMode,
		userData,
	]);

	function createResponse(checklistId: string): Promise<any> {
		return new Promise((resolve) => {
			const waitForSocket = () => {
				const socket = getSocket();
				if (socket?.connected) {
					socket.emit('joinChecklist', checklistId);
					socket.emit('createResponse', { checklistId });
					const handleResponse = (newResponse: any) => {
						socket.off('responseCreated', handleResponse);
						resolve(newResponse);
					};
					socket.on('responseCreated', handleResponse);
				} else {
					setTimeout(waitForSocket, 100);
				}
			};

			waitForSocket();
		});
	}

	useEffect(() => {
		const createNewResponse = async (checklistId: string) => {
			const newResponse = await createResponse(checklistId);
			setOpenedResponse(newResponse);
			setOpenedChecklistId(checklistId);
			setNavigation(`checklist_${checklistId}`);
			setResponseMode(true);
		};

		if (isIntegrated && urlCLId) {
			createNewResponse(urlCLId);
		} else if (urlCLId) {
			setOpenedChecklistId(urlCLId);
			setNavigation(`checklist_${urlCLId}`);
		} else if (urlFolderId) {
			setNavigation(`folder_${urlFolderId}`);
		} else if (urlResponse) {
			setOpenedResponse(urlResponse);
			setOpenedChecklistId(urlResponse.checklistId);
			setNavigation(`checklist_${urlResponse.checklistId}`);
			setResponseMode(true);
		}
	}, [
		urlCLId,
		urlFolderId,
		urlResponse,
		setResponseMode,
		setOpenedResponse,
		setOpenedChecklistId,
		setNavigation,
		isIntegrated,
	]);

	useEffect(() => {
		if (navigation === 'home') {
			window.history.pushState(null, '', '/');
		} else if (navigation.startsWith('folder_')) {
			const folderId = navigation.split('_')[1];
			window.history.pushState(null, '', `?folder=${folderId}`);
		} else if (navigation.startsWith('checklist_')) {
			const checklistId = navigation.split('_')[1];
			if (openedResponse) {
				window.history.pushState(
					null,
					'',
					`?response=${openedResponse.responseId}`
				);
			} else {
				window.history.pushState(null, '', `?checklist=${checklistId}`);
			}
		}
	}, [navigation, openedResponse]);

	if (isIntegrated && !responseMode) {
		return <LoadingFullScreen />;
	}

	return (
		<>
			{navigation === 'home' && <Home />}
			{navigation.startsWith('folder') && <Folder />}
			{(navigation.startsWith('checklist') ||
				navigation.startsWith('chapter') ||
				navigation.startsWith('question')) && (
				<>
					{openedChecklistId && (
						<ChecklistContainer
							id={openedChecklistId}
							response={openedResponse ?? undefined}
						/>
					)}
				</>
			)}
		</>
	);
};

function App() {
	const dispatch = useDispatch();
	const isIntegrated = useSelector(
		(state: RootState) => state.user.isIntegrated
	);
	const isAuthenticated = useSelector(
		(state: RootState) => state.user.isAuthenticated
	);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const iframeToken = urlParams.get('token');

		if (iframeToken) {
			localStorage.removeItem('token');
			localStorage.removeItem('refreshToken');

			localStorage.setItem('token', iframeToken);
			localStorage.setItem('tokenType', 'iframe');

			dispatch(setAuthenticated(true));
		}
	}, [dispatch]);

	const {
		data: userData,
		isLoading,
		isError,
	} = useMeQuery(undefined, {
		skip: !localStorage.getItem('token'),
	});

	const [signup, { isLoading: isSignupLoading }] = useSignupMutation();
	const [login, { isLoading: isLoginLoading }] = useLoginMutation();

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const userEmail = urlParams.get('userEmail');
		const userFirstName = urlParams.get('userFirstName');
		const userLastName = urlParams.get('userLastName');

		if (userEmail && userFirstName && userLastName) {
			dispatch(setIsIntegrated(true));
			localStorage.removeItem('token');
			localStorage.removeItem('refreshToken');
			dispatch(setAuthenticated(false));
			disconnectSocket();

			(async () => {
				try {
					// Try to login first
					const loginResponse = await login({
						email: userEmail,
						password: PASSWORD,
					}).unwrap();

					if (loginResponse.accessToken) {
						localStorage.setItem('token', loginResponse.accessToken);
						localStorage.setItem('refreshToken', loginResponse.refreshToken);
						localStorage.setItem('tokenType', 'standard');
						dispatch(setAuthenticated(true));
					}
				} catch (loginError) {
					console.error('Login error:', loginError);

					// If login fails, try to signup
					try {
						await signup({
							email: userEmail,
							password: PASSWORD,
							firstName: userFirstName,
							lastName: userLastName,
						}).unwrap();

						// Attempt login again after signup
						const loginResponse = await login({
							email: userEmail,
							password: PASSWORD,
						}).unwrap();

						if (loginResponse.accessToken) {
							localStorage.setItem('token', loginResponse.accessToken);
							localStorage.setItem('refreshToken', loginResponse.refreshToken);
							localStorage.setItem('tokenType', 'standard');
							dispatch(setAuthenticated(true));
						}
					} catch (signupError) {
						console.error('Signup error:', signupError);
					}
				}
			})();
		}
	}, [signup, login, dispatch]);

	useEffect(() => {
		if (userData) {
			dispatch(setAuthenticated(true));
			dispatch(setUserData(userData));
		} else if (isError) {
			dispatch(setAuthenticated(false));
			localStorage.removeItem('token');
			localStorage.removeItem('refreshToken');
			disconnectSocket();
		}
	}, [userData, isError, dispatch]);

	useEffect(() => {
		let intervalId: NodeJS.Timeout;
		if (isAuthenticated) {
			intervalId = setInterval(() => {
				const socket = getSocket();
				if (socket?.connected) {
					clearInterval(intervalId);
				}
			}, 5000);
		}

		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [isAuthenticated]);

	if (
		isLoading ||
		isSignupLoading ||
		isLoginLoading ||
		(isIntegrated && !isAuthenticated)
	) {
		return <LoadingFullScreen />;
	}

	return (
		<div className="App">
			<Toast />
			{!isAuthenticated ? (
				<LoginForm />
			) : (
				<NavigationProvider>
					<Routes />
				</NavigationProvider>
			)}
		</div>
	);
}

export default App;
