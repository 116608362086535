import React from 'react';
import { ReactComponent as LogoIcon } from '../icons/soopcl.svg';
import styles from './Navbar.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { logout } from 'store/userSlice';

export default function Navbar({
	before,
	after,
	border = false,
}: {
	before?: React.ReactNode;
	after?: React.ReactNode;
	border?: boolean;
}) {
	const dispatch = useDispatch();
	const isAuthenticated = useSelector(
		(state: RootState) => state.user.isAuthenticated
	);

	const handleLogout = () => {
		dispatch(logout());
	};

	return (
		<div className={`${styles.navbar} ${border ? styles.border : ''}`}>
			{before ? (
				before
			) : isAuthenticated ? (
				<div className={styles.logo}>
					<LogoIcon />
				</div>
			) : null}
			<div className={styles.flex}>
				{after ? (
					after
				) : isAuthenticated ? (
					<button onClick={handleLogout} className={styles.logoutButton}>
						Déconnexion
					</button>
				) : null}
			</div>
		</div>
	);
}
