import { useState } from 'react';
import { getSocket } from '../../store/socket';
import Switch from '../Switch/Switch';
import SingleSelectSettings from './Questions/SingleSelectSettings';
import MultipleSelectSettings from './Questions/MultipleSelectSettings';
import DateSettings from './Questions/DateSettings';
import NumberSettings from './Questions/NumberSettings';
import TextSettings from './Questions/TextSettings';
import MediaSettings from './Questions/MediaSettings';
import {
	Checklist as ChecklistType,
	Chapter as ChapterType,
	Question as QuestionType,
} from '../../sharedTypes';
import Rules from './Rules';
import styles from './LateralPanel.module.scss';

export default function LateralPannel({
	checklist,
	id,
	navigation,
	close,
}: {
	checklist: ChecklistType;
	id: string;
	navigation: string;
	close: () => void;
}) {
	const [panelNavigation, setPanelNavigation] = useState<boolean>(false);

	const question = checklist.chapters
		.flatMap((chapter: ChapterType) => chapter.questions || [])
		.find(
			(question) => question.questionId === navigation.split('_')[2]
		) as QuestionType;

	const handleUpdateQuestion = (
		question: QuestionType,
		updates: Partial<QuestionType>
	) => {
		getSocket()?.emit('updateQuestion', {
			...question,
			...updates,
			checklistId: id,
		});
	};

	return (
		<div className={styles.lateralPannel}>
			<div className={styles.bottomLineNavigation}>
				<div className={styles.flex}>
					<button
						onClick={() => setPanelNavigation(false)}
						className={`${styles.navButton} ${
							!panelNavigation ? styles.selected : ''
						}`}
					>
						Général
					</button>
					<button
						onClick={() => setPanelNavigation(true)}
						className={`${styles.navButton} ${
							panelNavigation ? styles.selected : ''
						}`}
					>
						Conditions
					</button>
				</div>
			</div>

			{panelNavigation ? (
				<Rules
					checklist={checklist}
					question={question}
					updateQuestion={handleUpdateQuestion}
				/>
			) : (
				<>
					<div
						onClick={() => {
							handleUpdateQuestion(question, { required: !question.required });
						}}
						className={styles.lateralPannelSection}
					>
						<div className={styles.spacedFlex}>
							<div className={styles.button}>RÉPONSE OBLIGATOIRE</div>
							<Switch isOn={question?.required} />
						</div>
						<p>
							En cas d’absence de réponse, la réponse sera marquée comme
							problématique lorsque la checklist aura été complétée.
						</p>
					</div>
					{question?.type &&
						(question.type === 'singleSelect' ? (
							<SingleSelectSettings
								question={question}
								updateQuestion={handleUpdateQuestion}
								settings
							/>
						) : question.type === 'multipleSelect' ? (
							<MultipleSelectSettings
								question={question}
								updateQuestion={handleUpdateQuestion}
								settings
							/>
						) : question.type === 'date' ? (
							<DateSettings
								question={question}
								updateQuestion={handleUpdateQuestion}
							/>
						) : question.type === 'number' ? (
							<NumberSettings
								question={question}
								updateQuestion={handleUpdateQuestion}
							/>
						) : question.type === 'text' ? (
							<TextSettings
								question={question}
								updateQuestion={handleUpdateQuestion}
							/>
						) : question.type === 'media' ? (
							<MediaSettings
								question={question}
								updateQuestion={handleUpdateQuestion}
							/>
						) : null)}{' '}
				</>
			)}
		</div>
	);
}
